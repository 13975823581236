import React from 'react'
import {pad} from '../components/names'

const Completionist = (props) => <div className={`ctdwn ended${props.className ? " " + props.className : ""}`}>Sker nu</div>;
        
const Ended = (props) => <div className={`ctdwn ended${props.className ? " " + props.className : ""}`}>Løbet er afsluttet</div>;

const Cancelled = (props) => <div className={`ctdwn ended${props.className ? " " + props.className : ""}`}>Løbet er aflyst</div>;

export const rendererFull = ({ days, hours, minutes, seconds, completed, props }) => {
    var now = new Date();
    var endRace = new Date(props.end);
    if(props.cancelled) {
      return <Cancelled className={props.className}/>;
    } else if (completed) {
      if(now < endRace) {
        return <Completionist className={props.className} />;
      } else {
        return <Ended className={props.className}/>;
      }
    } else {
      // Render a countdown
      return (
        <div className={`ctdwn${props.className ? " " + props.className : ""}`}>
          {days > 1 ? (<span data-name="dage">{days}</span>) : days > 0 ? (<span data-name="dag">{days}</span>) : ""}
          {days > 0 ? <span>:</span> : ""}
          {hours === 1 ? (<span data-name="time">{pad(hours)}</span>) : (<span data-name="timer">{pad(hours)}</span>)}
          {<span>:</span>} 
          {minutes === 1 ? (<span data-name="minut">{pad(minutes)}</span>) : (<span data-name="minutter">{pad(minutes)}</span>)}
          {<span>:</span>} 
          {seconds === 1 ? (<span data-name="sekund">{pad(seconds)}</span>) : (<span data-name="sekunder">{pad(seconds)}</span>)}
        </div>
      );
    } 
  }; 